import { Pipe, PipeTransform } from '@angular/core';
import { ConversationSummary, ConversationType } from '@dougs/user/dto';

@Pipe({
  name: 'conversationTypeColor',
  standalone: true,
})
export class ConversationTypeColorPipe implements PipeTransform {
  transform(conversation: ConversationSummary): string {
    if (conversation.type === ConversationType.INTERCOM) {
      return conversation.isClosed ? 'color-gray' : 'color-primary';
    }
    return 'color-admin';
  }
}
