<div class="user-conversation-summary">
  <div class="user-conversation-summary__icon py-4">
    <dougs-avatar
      [dougsTooltip]="(conversationSummaryComponentService.conversationAuthor$ | async)?.name"
      [image]="
        (conversationSummaryComponentService.conversationAuthor$ | async)?.avatarUrl ??
        'https://www.gravatar.com/avatar/null?s=256&d=mm'
      "
      size="medium"
    ></dougs-avatar>
  </div>
  <div class="user-conversation-summary__content mb-24">
    <p class="tiny" [ngClass]="[summary | conversationTypeColor]">{{ summary | conversationType }}</p>
    <div class="user-conversation-summary__content__header mb-8">
      <h5>{{ summary.date | date: (summary.date | conversationDateFormat) }}</h5>
      <div class="department-pills">
        @if (conversationSummaryComponentService.isRingoverConversation$ | async) {
          <dougs-pill
            *ngIf="(conversationSummaryComponentService.conversationAuthor$ | async)?.department"
            [type]="conversationSummaryComponentService.conversationAuthor$ | async | departmentPillColor"
          >
            {{
              (conversationSummaryComponentService.conversationAuthor$ | async)?.department | departmentLabel | async
            }}
          </dougs-pill>
        } @else {
          <dougs-pill
            [type]="distinctDepartmentMessage?.author | departmentPillColor"
            *ngFor="
              let distinctDepartmentMessage of summary.messageSummaries | distinctCollaboratorDepartment;
              trackBy: 'id' | trackBy
            "
          >
            {{ distinctDepartmentMessage.author.department | departmentLabel | async }}
          </dougs-pill>
        }
      </div>
    </div>
    <div class="user-conversation-summary__content__message__body mb-8 is-conv-summary">
      <p class="small color-primary-700 p-8">{{ summary.content }}</p>
    </div>
    <ng-container *ngIf="showMessages">
      <div
        class="user-conversation-summary__content__messages"
        [ngClass]="{ 'mb-8': !last }"
        *ngFor="
          let message of summary.messageSummaries;
          trackBy: 'id' | trackBy;
          let index = index;
          let last = last;
          let first = first
        "
      >
        <div
          class="user-conversation-summary__content__message__body mb-8"
          [ngClass]="{ 'is-dougs-user': message?.author?.isCollaborator }"
        >
          <dougs-avatar
            class="mt-4"
            size="small"
            [dougsTooltip]="message?.author?.name"
            [image]="message?.author?.avatarUrl ?? 'https://www.gravatar.com/avatar/null?s=256&d=mm'"
          ></dougs-avatar>
          <p class="small color-primary-700 p-8">{{ message.content }}</p>
        </div>
      </div>
    </ng-container>
    <div class="user-conversation-summary__content__footer">
      <p (click)="toggleConversation()" *ngIf="summary.messageSummaries?.length" class="tiny color-primary-700 pointer">
        {{ showMessages ? 'Cacher' : 'Voir' }} {{ summary.messageSummaries.length > 1 ? 'les ' : "l'"
        }}{{ summary.messageSummaries.length > 1 ? summary.messageSummaries.length + ' ' : '' }}échange{{
          summary.messageSummaries.length > 1 ? 's' : ''
        }}<i
          class="fal color-gray ml-8"
          [ngClass]="{ 'fa-chevron-down': !showMessages, 'fa-chevron-up': showMessages }"
        ></i>
      </p>

      <div class="user-conversation-summary__content__footer__right mr-4">
        <dougs-avatar
          [dougsTooltip]="message?.author?.name"
          *ngFor="let message of summary.messageSummaries | distinctAuthor; trackBy: 'id' | trackBy"
          [image]="message?.author?.avatarUrl ?? 'https://www.gravatar.com/avatar/null?s=256&d=mm'"
          size="small"
        ></dougs-avatar>
      </div>
      <a
        class="user-conversation-summary__content__footer__link"
        (click)="conversationSummaryComponentService.onOpenIntercomLink()"
        *ngIf="summary.type === ConversationType.INTERCOM"
        [href]="'https://app.intercom.com/a/apps/m2r93wjs/conversations/' + summary.id"
        target="_blank"
      >
        <i class="fal fa-arrow-up-right-from-square color-primary mr-4"></i>
        <p class="tiny color-primary">Ouvrir sur Intercom</p>
      </a>
      <div
        class="user-conversation-summary__content__footer__duration"
        *ngIf="summary.type === ConversationType.RINGOVER && summary.duration"
      >
        <i class="fal fa-clock-eight"></i>
        <p class="tiny">{{ summary.duration | secondsToMinutes }}</p>
      </div>
    </div>
  </div>
</div>
