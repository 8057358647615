import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';

@Injectable()
export class UserHashHttpService {
  constructor(private readonly http: HttpClient) {}
  getIntercomUserHash(userId: number): Observable<{
    user_hash: string;
  }> {
    return this.http.get<{
      user_hash: string;
    }>(`${AppConfig.settings.legacyApiServerUrl}/users/${userId}/intercom/hash`);
  }
}
