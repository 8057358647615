import { Pipe, PipeTransform } from '@angular/core';
import { MessageSummary } from '@dougs/user/dto';

@Pipe({
  name: 'distinctCollaboratorDepartment',
  standalone: true,
})
export class DistinctCollaboratorDepartmentPipe implements PipeTransform {
  transform(messages: MessageSummary[]): MessageSummary[] {
    return [
      ...new Map(
        messages
          ?.filter((msg) => msg?.author?.isCollaborator && !!msg?.author?.department)
          ?.map((message) => [message.author?.department, message]),
      ).values(),
    ];
  }
}
