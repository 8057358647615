import { Pipe } from '@angular/core';

@Pipe({
  name: 'conversationDateFormat',
  standalone: true,
})
export class ConversationDateFormatPipe {
  transform(date: string): string {
    const isCurrentYear: boolean = new Date(date).getFullYear() === new Date().getFullYear();
    return isCurrentYear ? 'dd MMMM' : 'dd MMM yyyy';
  }
}
