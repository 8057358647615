import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  AutofocusDirective,
  ButtonComponent,
  CheckboxComponent,
  ControlFormFieldDirective,
  FormFieldComponent,
  LabelFormFieldDirective,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
  SelectComponent,
  SelectOptionComponent,
  TrackByPipe,
} from '@dougs/ds';
import { DestroyUserModalComponentService } from '../../services/destroy-user-modal.component.service';

const ANIMATION = '200ms ease-in-out';

@Component({
  selector: 'dougs-destroy-user-modal',
  standalone: true,
  imports: [
    CommonModule,
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    ButtonComponent,
    ModalFooterDirective,
    FormFieldComponent,
    ReactiveFormsModule,
    AutofocusDirective,
    ControlFormFieldDirective,
    LabelFormFieldDirective,
    SelectComponent,
    SelectOptionComponent,
    TrackByPipe,
    CheckboxComponent,
  ],
  providers: [DestroyUserModalComponentService],
  templateUrl: './destroy-user-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ height: '0', overflow: 'hidden', marginTop: 0, marginBottom: 0, opacity: 0 }),
        animate(ANIMATION, style({ height: '*', marginTop: '*', marginBottom: '1.5rem', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ height: '*', overflow: 'hidden', marginTop: '*', marginBottom: '1.5rem', opacity: 1 }),
        animate(ANIMATION, style({ height: 0, marginTop: 0, marginBottom: 0, opacity: 0 })),
      ]),
    ]),
  ],
})
export class DestroyUserModalComponent {
  constructor(public readonly destroyUserModalComponentService: DestroyUserModalComponentService) {}
}
