import { CommonModule } from '@angular/common';
import { Component, HostListener } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { ReplaceActiveCompanyIdInUrlPipe } from '@dougs/core/routing';
import {
  AutofocusDirective,
  ButtonComponent,
  ControlFormFieldDirective,
  FormFieldComponent,
  LoaderComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
  PillComponent,
  TrackByPipe,
  UserLabelPillPipe,
} from '@dougs/ds';
import { UserStateService } from '@dougs/user/shared';
import { CanDestroyUserPipe } from '../../pipes/can-destroy-user.pipe';
import { EmojiRatingPipe } from '../../pipes/emoji-rating.pipe';
import { UserSearchModalComponentService } from '../../services/user-search-modal.component.service';

@Component({
  selector: 'dougs-search-modal',
  templateUrl: './user-search-modal.component.html',
  styleUrls: ['./user-search-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ModalTitleDirective,
    ModalContentDirective,
    ModalCloseDirective,
    ModalFooterDirective,
    ButtonComponent,
    ReactiveFormsModule,
    FormFieldComponent,
    PillComponent,
    LoaderComponent,
    ControlFormFieldDirective,
    AutofocusDirective,
    EmojiRatingPipe,
    TrackByPipe,
    RouterLink,
    CanDestroyUserPipe,
    ReplaceActiveCompanyIdInUrlPipe,
    UserLabelPillPipe,
    InfiniteScrollDirective,
  ],
  providers: [UserSearchModalComponentService],
})
export class UserSearchModalComponent {
  constructor(
    public readonly userSearchModalComponentService: UserSearchModalComponentService,
    public readonly userStateService: UserStateService,
  ) {}

  @HostListener('document:keydown', ['$event'])
  async handleKeyboardEvent(event: KeyboardEvent): Promise<void> {
    await this.userSearchModalComponentService.handleKeyboardEvent(event);
  }
}
