import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppConfig } from '@dougs/core/config';
import {
  ButtonComponent,
  LoaderComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
  TrackByPipe,
} from '@dougs/ds';
import { UserConversationSummariesStateService, UserStateService } from '@dougs/user/shared';
import { UserConversationSummaryModalComponentService } from '../../services/user-conversation-summary-modal.component.service';
import { ConversationSkeletonComponent } from './conversation-skeleton/conversation-skeleton.component';
import { UserConversationSummaryComponent } from './user-conversation-summary/user-conversation-summary.component';

@Component({
  selector: 'dougs-user-conversation-summary-modal',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    LoaderComponent,
    ModalCloseDirective,
    ModalContentDirective,
    ModalFooterDirective,
    ModalTitleDirective,
    TrackByPipe,
    UserConversationSummaryComponent,
    ConversationSkeletonComponent,
  ],
  templateUrl: './user-conversation-summary-modal.component.html',
  styleUrls: ['./user-conversation-summary-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UserConversationSummaryModalComponentService],
})
export class UserConversationSummaryModalComponent {
  constructor(
    public readonly userConversationSummaryModalComponentService: UserConversationSummaryModalComponentService,
    public readonly userConversationSummariesStateService: UserConversationSummariesStateService,
    public readonly userStateService: UserStateService,
  ) {}

  protected readonly legacyApiServerUrl = AppConfig.settings.legacyApiServerUrl;
}
