import { Pipe, PipeTransform } from '@angular/core';
import { ConversationSummary, ConversationType } from '@dougs/user/dto';

@Pipe({
  name: 'conversationType',
  standalone: true,
})
export class ConversationTypePipe implements PipeTransform {
  transform(conversation: ConversationSummary): string {
    if (conversation.type === ConversationType.INTERCOM) {
      return conversation.isClosed ? 'Conv. fermée' : 'Conv. ouverte';
    }
    return 'Appel Ringover';
  }
}
