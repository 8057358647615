<ng-container *ngIf="userConversationSummaryModalComponentService.refreshConversationSummaries$ | async"></ng-container>
<div dougsModalTitle>
  <h6>Résumé des conversations</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent="large">
  @if (userConversationSummaryModalComponentService.isLoading$ | async) {
    @for (i of [1, 2, 3, 4]; track i) {
      <dougs-conversation-skeleton />
    }
  } @else if (
    !(userConversationSummaryModalComponentService.isLoading$ | async) &&
    (userConversationSummariesStateService.userConversationSummaries$ | async)?.length
  ) {
    <div class="user-conversation-summaries">
      <div class="user-conversation-summaries__timeline"></div>
      <div>
        @for (summary of userConversationSummariesStateService.userConversationSummaries$ | async; track summary.id) {
          <dougs-user-conversation-summary [index]="$index" [summary]="summary" />
        }
      </div>
    </div>
  }
  @if (
    !(userConversationSummaryModalComponentService.isLoading$ | async) &&
    !(userConversationSummariesStateService.userConversationSummaries$ | async)?.length
  ) {
    <p class="color-primary-700">Aucune conversation n'a été ouverte avec ce client.</p>
  }
</div>
<div dougsModalFooter>
  <dougs-button dougsModalClose color="secondary">Fermer</dougs-button>
  <a
    rel="noopener"
    [href]="
      legacyApiServerUrl +
      '/users/' +
      (userStateService.activeUserIdChanged$ | async)?.id +
      '/intercom/new-conversation'
    "
    target="_blank"
  >
    <dougs-button size="medium" color="secondary">
      <i class="fal fa-arrow-up-right-from-square mr-8 color-primary-700"></i>
      Nouvelle conversation
    </dougs-button>
  </a>
</div>
