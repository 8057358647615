import { Pipe, PipeTransform } from '@angular/core';
import { Author, MessageSummary } from '@dougs/user/dto';

@Pipe({
  name: 'distinctAuthor',
  standalone: true,
})
export class DistinctAuthorPipe implements PipeTransform {
  transform(messages: MessageSummary[]): MessageSummary[] {
    return [
      ...new Map(
        messages
          ?.filter((msg): msg is MessageSummary & { author: Author } => !!msg?.author?.avatarUrl)
          ?.map((message) => [message.author.id, message]),
      ).values(),
    ];
  }
}
