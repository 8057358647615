import { Injectable } from '@angular/core';
import { BehaviorSubject, concatMap, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UserConversationSummariesStateService, UserStateService } from '@dougs/user/shared';

@Injectable()
export class UserConversationSummaryModalComponentService {
  isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading$: Observable<boolean> = this.isLoading.asObservable();

  refreshConversationSummaries$: Observable<void> = this.userStateService.activeUserIdChanged$.pipe(
    tap(() => this.isLoading.next(true)),
    concatMap((activeUser) => this.userConversationSummariesStateService.getUserConversationSummaries(activeUser.id)),
    tap(() => this.isLoading.next(false)),
  );

  constructor(
    private readonly userStateService: UserStateService,
    private readonly userConversationSummariesStateService: UserConversationSummariesStateService,
  ) {}
}
