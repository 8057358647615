import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Intercom } from '@supy-io/ngx-intercom';
import { concatMap, Observable, tap } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { DrawerService } from '@dougs/ds';
import { UserStateService } from '@dougs/user/shared';
import { UserHashHttpService } from './user-hash.http';

@Component({
  selector: 'dougs-intercom',
  templateUrl: './intercom.component.html',
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UserHashHttpService],
})
export class IntercomComponent implements OnInit {
  loggedInIntercom$!: Observable<{ user_hash: string }>;

  constructor(
    private readonly intercom: Intercom,
    private readonly userStateService: UserStateService,
    private readonly userHashHttpService: UserHashHttpService,
    private readonly drawerService: DrawerService,
  ) {}

  updateAlignementOnDrawer$: Observable<any> = this.drawerService.drawerOpened$.pipe(
    tap((isDrawerOpen: boolean) => this.updateIntercomAlignement(isDrawerOpen ? 'left' : 'right')),
  );

  ngOnInit(): void {
    this.intercom.loadIntercom(
      {
        appId: AppConfig.settings.intercom.appId,
      },
      (event?: Event) => {
        // Double boot intercom to get Intercom object on window
        // Should shut down to remove session if user is logged out or session is expired
        this.bootIntercom();
        this.intercom.shutdown();
        this.bootIntercom();
      },
    );

    this.loggedInIntercom$ = this.userStateService.loggedInUser$.pipe(
      tap((user) => {
        if (user) {
          this.intercom.update({
            hide_default_launcher: user.isAccountantOrAdmin,
          });
        }
      }),
      concatMap((user) => this.userHashHttpService.getIntercomUserHash(user.id)),
      tap(({ user_hash }) => {
        const user = this.userStateService.loggedInUser;

        if (user_hash) {
          this.intercom.update({
            app_id: AppConfig.settings.intercom.appId,
            user_id: user.id.toString(),
            email: user.email,
            created_at: (new Date(user.createdAt).getTime() / 1000) | 0,
            user_hash: user_hash,
          });
        }
      }),
    );
  }

  private updateIntercomAlignement(alignment = 'right'): void {
    (<any>window).Intercom('update', {
      app_id: AppConfig.settings.intercom.appId,
      alignment,
    });
  }

  private bootIntercom(): void {
    (<any>window).Intercom('boot', {
      app_id: AppConfig.settings.intercom.appId,
    });
  }
}
