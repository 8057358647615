import { Injectable } from '@angular/core';
import { map, Observable, ReplaySubject } from 'rxjs';
import { MetricsService } from '@dougs/core/metrics';
import { Author, ConversationSummary, ConversationType } from '@dougs/user/dto';

@Injectable()
export class ConversationSummaryComponentService {
  constructor(private readonly metricsService: MetricsService) {}

  private readonly conversationSummary: ReplaySubject<ConversationSummary> = new ReplaySubject<ConversationSummary>(1);
  conversationSummary$ = this.conversationSummary.asObservable();

  conversationAuthor$: Observable<Author | null | undefined> = this.conversationSummary$.pipe(
    map((conversation) =>
      conversation.type === ConversationType.INTERCOM
        ? conversation.messageSummaries
            ?.filter((message) => message?.author?.isCollaborator && !!message?.author?.avatarUrl)
            ?.reverse()?.[0]?.author
        : conversation.collaborator,
    ),
  );

  isRingoverConversation$: Observable<boolean> = this.conversationSummary$.pipe(
    map((conversation) => conversation.type === ConversationType.RINGOVER),
  );

  setConversationSummary(conversation: ConversationSummary): void {
    this.conversationSummary.next(conversation);
  }

  onOpenIntercomLink(): void {
    this.metricsService.pushMixpanelEvent('Control Panel Conversations Intercom Link Clicked');
  }
}
