import { Inject, Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { FlashMessagesService, MODAL_DATA, ModalRef } from '@dougs/ds';
import { UserDestroyParams, UserSearchResult } from '@dougs/user/dto';
import { UserStateService } from '@dougs/user/shared';
import { userDeletionReasons } from '../const/user-deletion-reasons.const';

interface DestroyUserForm {
  force: FormControl<boolean>;
  notify: FormControl<boolean | null>;
  delay: FormControl<number | null>;
  reason: FormControl<string | null>;
}

@Injectable()
export class DestroyUserModalComponentService {
  private readonly isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  isLoading$: Observable<boolean> = this.isLoading.asObservable();
  form: FormGroup<DestroyUserForm> = new FormGroup({
    force: new FormControl<boolean>(false, { nonNullable: true }),
    notify: new FormControl<boolean | null>(true),
    delay: new FormControl<number | null>(5),
    reason: new FormControl<string | null>(null),
  });
  userDeletionReasons = userDeletionReasons;
  formValueChanges$: Observable<void> = this.form.controls.force.valueChanges.pipe(
    map((value) => {
      if (value) {
        this.form.controls.notify.disable();
        this.form.controls.delay.disable();
      } else {
        this.form.controls.notify.enable();
        this.form.controls.delay.enable();
      }

      this.form.updateValueAndValidity();
    }),
  );

  constructor(
    @Inject(MODAL_DATA)
    public user: UserSearchResult,
    private readonly userStateService: UserStateService,
    public readonly modalRef: ModalRef,
    private readonly flashMessagesService: FlashMessagesService,
  ) {}

  async submitForm(): Promise<void> {
    this.isLoading.next(true);

    if (await this.userStateService.destroyUser(this.user.userId, this.mapUserToDestroyUserParams())) {
      await this.switchCurrentUserIfNeeded();
      this.modalRef.close();
      this.flashMessagesService.show("L'utilisateur a été supprimé avec succès", { type: 'success' });
    }

    this.isLoading.next(false);
  }

  private async switchCurrentUserIfNeeded(): Promise<void> {
    if (this.userStateService.activeUser.id === this.user.userId) {
      await this.userStateService.refreshActiveUser(this.userStateService.loggedInUser);
    }
  }

  private mapUserToDestroyUserParams(): UserDestroyParams {
    return {
      force: this.form.controls.force.value,
      notify: this.form.controls.notify.value,
      delay: this.form.controls.delay.value,
      reason: this.form.controls.reason.value,
    };
  }
}
