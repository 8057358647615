<ng-container *ngIf="destroyUserModalComponentService.formValueChanges$"></ng-container>

<form [formGroup]="destroyUserModalComponentService.form" (ngSubmit)="destroyUserModalComponentService.submitForm()">
  <div dougsModalTitle>
    <h6>Suppression d'un utilisateur</h6>
    <i dougsModalClose class="fal fa-times"></i>
  </div>

  <div dougsModalContent="medium">
    <p class="mb-16">
      Êtes-vous sûr(e) de vouloir supprimer l'utilisateur
      {{ destroyUserModalComponentService.user.title }} ?
    </p>
    <dougs-form-field>
      <dougs-checkbox
        formControlName="force"
        appearance="toggle"
        label="Supprimer immédiatement (<b>Attention !</b>)"
      ></dougs-checkbox>
    </dougs-form-field>
    <div *ngIf="!destroyUserModalComponentService.form.controls.force.value" [@slideInOut]>
      <dougs-form-field>
        <dougs-checkbox
          formControlName="notify"
          appearance="toggle"
          label="Envoyer un email de notification"
        ></dougs-checkbox>
      </dougs-form-field>
      <dougs-form-field>
        <label for="delay" dougsFormFieldLabel>Délai avant suppression (jours)</label>
        <input dougsFormFieldControl type="number" id="delay" placeholder="5" formControlName="delay" />
      </dougs-form-field>
    </div>
    <dougs-form-field>
      <label for="reason" dougsFormFieldLabel>Indiquez la raison de suppression</label>
      <dougs-select formControlName="reason" id="reason" placeholder="Raison..." dougsFormFieldControl>
        <dougs-select-option
          *ngFor="let reason of destroyUserModalComponentService.userDeletionReasons; trackBy: 'id' | trackBy"
          [value]="reason.value"
        >
          {{ reason.label }}
        </dougs-select-option>
      </dougs-select>
    </dougs-form-field>
  </div>

  <div dougsModalFooter>
    <dougs-button color="secondary" dougsModalClose>Non, ne pas supprimer l'utilisateur</dougs-button>
    <dougs-button [disabled]="!!(destroyUserModalComponentService.isLoading$ | async)" color="delete" type="submit">
      Oui, supprimer l'utilisateur ainsi que sa société
    </dougs-button>
  </div>
</form>
