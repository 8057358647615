import { Pipe, PipeTransform } from '@angular/core';
import { map, Observable } from 'rxjs';
import { UserSearchResult } from '@dougs/user/dto';
import { UserStateService } from '@dougs/user/shared';

@Pipe({
  name: 'canDestroyUser',
  standalone: true,
})
export class CanDestroyUserPipe implements PipeTransform {
  constructor(private readonly userStateService: UserStateService) {}

  transform(value: UserSearchResult): Observable<boolean> {
    return this.userStateService.loggedInUser$.pipe(
      map(
        (loggedInUser) =>
          loggedInUser.isAccountantOrAdmin &&
          loggedInUser.flags.includes('canDestroyUser') &&
          value.userId !== loggedInUser.id,
      ),
    );
  }
}
