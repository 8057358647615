import { Pipe, PipeTransform } from '@angular/core';
import { Pill } from '@dougs/ds';
import { Author } from '@dougs/user/dto';

@Pipe({
  name: 'departmentPillColor',
  standalone: true,
})
export class DepartmentPillColorPipe implements PipeTransform {
  transform(author: Author): Pill {
    switch (author?.department) {
      case 'care':
      case 'fiscal':
        return 'success';
      case 'social':
      case 'sales':
        return 'error';
      case 'accounting':
      case 'charteredAccountant':
      case 'tech':
        return 'primary';
      case 'legal':
      case 'internalFinance':
        return 'admin';
      case 'assetsManagement':
        return 'warning';
      default:
        return 'primary';
    }
  }
}
