import { Pipe, PipeTransform } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ConfigBackService } from '@dougs/core/config-back';

@Pipe({
  name: 'departmentLabel',
  standalone: true,
})
export class DepartmentLabelPipe implements PipeTransform {
  constructor(private readonly configBackService: ConfigBackService) {}

  transform(departmentCode: string): Observable<string> {
    return this.configBackService.departments$.pipe(
      map((departments) => departments?.find((department) => department.code === departmentCode)?.name ?? ''),
    );
  }
}
