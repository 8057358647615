import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  concatMap,
  filter,
  lastValueFrom,
  map,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
} from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { replaceCompanyIdInUrlAsCommand } from '@dougs/core/routing';
import { ModalRef, ModalService } from '@dougs/ds';
import { User, UserSearchResult } from '@dougs/user/dto';
import { CachedUserSearchResultsService, UserStateService } from '@dougs/user/shared';
import { DestroyUserModalComponent } from '../modals/destroy-user-modal/destroy-user-modal.component';

@Injectable()
export class UserSearchModalComponentService {
  selectedIndex = 0;

  searchResults: UserSearchResult[] = [];

  private readonly isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading$: Observable<boolean> = this.isLoading.asObservable();

  searchControl: FormControl<string> = new FormControl<string>('', { nonNullable: true });

  searchValueChanges$: Observable<UserSearchResult[]> = this.searchControl.valueChanges.pipe(
    startWith(''),
    distinctUntilChanged(),
    tap(() => this.isLoading.next(true)),
    debounceTime(300),
    switchMap((search) =>
      search
        ? this.userStateService.searchUsers(search).pipe(map((users) => this.searchUsers(users)))
        : of(this.cachedUserSearchResultsService.cachedUserSearchResults),
    ),
    map((searchResults) => (this.searchResults = searchResults)),
    tap(() => this.isLoading.next(false)),
  );

  private readonly queueScroll: Subject<void> = new Subject<void>();
  private readonly queueScroll$ = this.queueScroll.asObservable();

  loadMoreUsers$: Observable<UserSearchResult[]> = this.queueScroll$.pipe(
    filter(() => !!this.searchControl.value),
    debounceTime(10),
    concatMap(() =>
      this.userStateService.loadMoreUsers(this.searchControl.value).pipe(map((users) => this.searchUsers(users))),
    ),
    map((searchResults) => (this.searchResults = [...this.searchResults, ...searchResults])),
  );

  constructor(
    private readonly userStateService: UserStateService,
    private readonly cachedUserSearchResultsService: CachedUserSearchResultsService,
    private readonly modalService: ModalService,
    private readonly modalRef: ModalRef,
    private readonly router: Router,
  ) {}

  searchUsers(users: User[]): UserSearchResult[] {
    return users.map((user) => this.cachedUserSearchResultsService.buildUserResult(user, null));
  }

  searchResultClicked(event: MouseEvent): void {
    if (event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }
    this.modalRef.close();
  }

  async redirectToCompanyLink(searchResult: UserSearchResult): Promise<void> {
    await this.router.navigate(replaceCompanyIdInUrlAsCommand(searchResult.companyId.toString(), this.router.url));
  }

  async handleKeyboardEvent(event: KeyboardEvent): Promise<void> {
    if (this.searchResults.length === 0) {
      return;
    }

    if (event.key === 'ArrowDown') {
      if (this.selectedIndex < this.searchResults.length - 1) {
        this.selectedIndex++;
      }
    } else if (event.key === 'ArrowUp') {
      if (this.selectedIndex > 0) {
        this.selectedIndex--;
      }
    } else if (event.key === 'Enter') {
      await this.redirectToCompanyLink(this.searchResults[this.selectedIndex]);
      this.modalRef.close();
    }
  }

  async openDiaExport(): Promise<void> {
    const { DiaExportComponentModal } = await import('../modals/dia-export-modal/dia-export-modal.component');
    await lastValueFrom(this.modalService.open(DiaExportComponentModal).afterClosed$);
  }

  destroyUser(user: UserSearchResult, event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.modalService.open(DestroyUserModalComponent, { data: user });
  }

  onScroll(): void {
    this.queueScroll.next();
  }
}
