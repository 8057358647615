import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MetricsService } from '@dougs/core/metrics';
import {
  AvatarComponent,
  ButtonComponent,
  DougsDatePipe,
  PillComponent,
  TooltipDirective,
  TrackByPipe,
} from '@dougs/ds';
import { ConversationSummary, ConversationType } from '@dougs/user/dto';
import { ConversationDateFormatPipe } from '../../../pipes/conversation-date-format.pipe';
import { ConversationTypeColorPipe } from '../../../pipes/conversation-type-color.pipe';
import { ConversationTypePipe } from '../../../pipes/conversation-type.pipe';
import { DepartmentLabelPipe } from '../../../pipes/department-label.pipe';
import { DepartmentPillColorPipe } from '../../../pipes/department-pill-color.pipe';
import { DistinctAuthorPipe } from '../../../pipes/distinct-author.pipe';
import { DistinctCollaboratorDepartmentPipe } from '../../../pipes/distinct-collaborator-department.pipe';
import { SecondsToMinutesPipe } from '../../../pipes/seconds-to-minutes.pipe';
import { ConversationSummaryComponentService } from '../../../services/conversation-summary.component.service';

@Component({
  selector: 'dougs-user-conversation-summary',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    TrackByPipe,
    AvatarComponent,
    TooltipDirective,
    DistinctAuthorPipe,
    DougsDatePipe,
    ConversationTypePipe,
    ConversationTypeColorPipe,
    PillComponent,
    DistinctCollaboratorDepartmentPipe,
    DepartmentPillColorPipe,
    DepartmentLabelPipe,
    SecondsToMinutesPipe,
    ConversationDateFormatPipe,
  ],
  providers: [ConversationSummaryComponentService],
  templateUrl: './user-conversation-summary.component.html',
  styleUrls: ['./user-conversation-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserConversationSummaryComponent {
  private _summary!: ConversationSummary;
  @Input() set summary(value: ConversationSummary) {
    this._summary = value;
    this.conversationSummaryComponentService.setConversationSummary(value);
  }

  get summary(): ConversationSummary {
    return this._summary;
  }

  toggleConversation(): void {
    this.showMessages = !this.showMessages;
    if (this.showMessages) {
      this.metricsService.pushMixpanelEvent('Control Panel Conversations Detail Opened');
    }
  }

  @Input() index!: number;

  showMessages = false;

  constructor(
    public readonly conversationSummaryComponentService: ConversationSummaryComponentService,
    private readonly metricsService: MetricsService,
  ) {}

  protected readonly ConversationType = ConversationType;
}
