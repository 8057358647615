<ng-container *ngIf="userSearchModalComponentService.searchValueChanges$ | async"></ng-container>
<ng-container *ngIf="userSearchModalComponentService.loadMoreUsers$ | async"></ng-container>
<div class="search-modal">
  <div dougsModalTitle>
    <h6>Rechercher</h6>
    <i dougsModalClose class="fal fa-times"></i>
  </div>
  <div
    dougsModalContent
    infiniteScroll
    (scrolled)="userSearchModalComponentService.onScroll()"
    [immediateCheck]="true"
    [scrollWindow]="false"
  >
    <div class="content-header mb-16">
      <dougs-form-field [noMargin]="true">
        <input
          dougsFormFieldControl
          dougsAutofocus
          type="text"
          placeholder="Rechercher un utilisateur..."
          [formControl]="userSearchModalComponentService.searchControl"
        />
        <i class="fal fa-search" dougsFormFieldSuffix></i>
      </dougs-form-field>
      <dougs-button
        (click)="userSearchModalComponentService.openDiaExport()"
        *ngIf="(userStateService.loggedInUser$ | async)?.flags?.includes('diaExport')"
        size="small"
      >
        <i class="fal fa-cloud-download mr-4"></i>
        Export dia
      </dougs-button>
    </div>

    <div *ngIf="!(userSearchModalComponentService.isLoading$ | async)">
      <h3>{{ userSearchModalComponentService.searchControl.value ? 'Résultats' : 'Historique' }} de recherche</h3>
      <ul class="search-results">
        <li
          *ngFor="
            let result of userSearchModalComponentService.searchResults;
            let index = index;
            trackBy: 'id' | trackBy
          "
        >
          <a
            class="disable-user-select"
            [class.selected]="userSearchModalComponentService.selectedIndex === index"
            [routerLink]="result.companyId | replaceActiveCompanyIdInUrl | async"
            (click)="userSearchModalComponentService.searchResultClicked($event)"
          >
            <div class="column">
              <h6 class="mb-4">{{ result.title }}</h6>
              <p class="mb-4 tiny">{{ result.subtitle }}</p>
              <span class="tiny italic">{{ result.text }}</span>
              <dougs-button
                *ngIf="result | canDestroyUser | async"
                (click)="userSearchModalComponentService.destroyUser(result, $event)"
                color="delete"
                size="small"
                class="mt-8"
              >
                Supprimer
              </dougs-button>
            </div>
            <div class="pills" *ngIf="result.labels">
              <dougs-pill *ngFor="let label of result?.labels || []" [type]="label | userLabelPill">
                {{ label.label }}
              </dougs-pill>
            </div>
            <div class="avatar-container">
              <img alt="Avatar" class="avatar" title="avatar" [src]="result.avatar" *ngIf="result.avatar" />
              <img
                alt="Rating"
                class="intercom-rating"
                *ngIf="result.intercomRating"
                [src]="result.intercomRating | emojiRating"
              />
            </div>
          </a>
        </li>
      </ul>
      <div
        class="no-results"
        *ngIf="
          userSearchModalComponentService.searchResults.length === 0 &&
          !(userSearchModalComponentService.isLoading$ | async)
        "
      >
        Pas de résultat
      </div>
    </div>
    <dougs-loader *ngIf="userSearchModalComponentService.isLoading$ | async"></dougs-loader>
  </div>
</div>
