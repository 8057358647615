import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emojiRating',
  standalone: true,
})
export class EmojiRatingPipe implements PipeTransform {
  transform(rating: string): string {
    switch (Number(rating)) {
      case 1:
        return 'images/emoji/pouting-face.png';
      case 2:
        return 'images/emoji/white-frowning-face.png';
      case 3:
        return 'images/emoji/neutral-face.png';
      case 4:
        return 'images/emoji/grinning-face.png';
      case 5:
        return 'images/emoji/star-struck-face.png';
      default:
        return '';
    }
  }
}
