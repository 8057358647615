export const userDeletionReasons = [
  { label: 'Non gérable - Caisse', value: 'cashRegister' },
  { label: "Non gérable - A l'étranger", value: 'foreignCountry' },
  { label: 'Non qualifié - Injoignable', value: 'unreachable' },
  { label: 'Non qualifié - Pas intéressé', value: 'notInterested' },
  { label: 'Qualifié - Concurrence', value: 'competitor' },
  { label: 'Qualifié - Budget', value: 'price' },
  { label: 'Qualifié - Injoignable', value: 'unreachableAfterQualification' },
  { label: 'Non gérable - Dépassement des seuils', value: 'threshold' },
  { label: 'Non gérable - Liquidation', value: 'liquidation' },
  { label: 'Non qualifié - Fausses données', value: 'wrongLead' },
  { label: 'Non gérable - TVA sur marge', value: 'vat' },
  { label: 'Non gérable - Bénéfices agricoles', value: 'agricultural' },
  { label: 'Non gérable - Compte mandant', value: 'clientAccount' },
  { label: "Non gérable - Création d'association", value: 'associationCreation' },
  { label: 'Non gérable - Formation', value: 'trainingOrganism' },
  { label: 'Non gérable - Multi TVA', value: 'multipleVAT' },
  { label: 'Non gérable - Corse', value: 'corse' },
  { label: 'Non gérable - Dom-Tom', value: 'domTom' },
  { label: "Non gérable - SCI à l'IR", value: 'SCI-IR' },
];
